<template>
	<div id="invbox">
		<div id="btn" style="margin-bottom: 30px; display: flex; justify-content: flex-end;">
			<el-date-picker v-model="datetxt" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
				:default-time="['00:00:00','23:59:59']">
			</el-date-picker>
			<el-select style="margin-left: 10px;" v-model="OrderBy" clearable placeholder="请选择">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-button size='small' style="margin-left: 10px;" @click='SELFUN'>查询</el-button>
		</div>
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane label="所有单据" name="0">
			</el-tab-pane>
			<el-tab-pane label="未处理单据" name="1"></el-tab-pane>
			<el-tab-pane label="已处理未发送单据" name="2"></el-tab-pane>
			<el-tab-pane label="邮箱错误单据" name="3"></el-tab-pane>
			<el-tab-pane label="处理完成单据" name="4"></el-tab-pane>
		</el-tabs>
		<el-table :data="tableData" stripe style="width: 100%">
			<el-table-column prop="InvoiceType" label="发票类型">
			</el-table-column>
			<el-table-column prop="TaxNumber" label="税号">
			</el-table-column>
			<el-table-column prop="UnitAddress" label="单位地址">
			</el-table-column>
			<el-table-column prop="InvoicePhone" label="电话号码">
			</el-table-column>
			<el-table-column prop="InvoiceBank" label="开户银行">
			</el-table-column>
			<el-table-column prop="BankAccount" label="银行账号">
			</el-table-column>
			<el-table-column prop="Email" label="邮箱">
			</el-table-column>
			<el-table-column prop="UserName" label="账户名称">
			</el-table-column>
			<el-table-column prop="Out_trade_no" label="商户订单号">
			</el-table-column>
			<el-table-column prop="GoodsName" label="商品名">
			</el-table-column>
			<el-table-column prop="Dealer" label="支付平台" width="80">
			</el-table-column>
			<el-table-column prop="Time" label="时间">
			</el-table-column>
			<el-table-column prop="InvoiceState" label="状态" width="100">
				<template slot-scope="scope">
					<p style="color: #E6A23C;font-weight: bold;" v-if="scope.row.InvoiceState=='未开票'">{{scope.row.InvoiceState}}</p>
					<p style="color: #00aaff;font-weight: bold;" v-if="scope.row.InvoiceState=='已申请发票'">{{scope.row.InvoiceState}}</p>
					<p style="color: red;font-weight: bold;" v-if="scope.row.InvoiceState=='邮箱错误'">{{scope.row.InvoiceState}}</p>
					<p style="color: #67C23A;font-weight: bold;" v-if="scope.row.InvoiceState=='发送成功'">{{scope.row.InvoiceState}}</p>
				</template>
			</el-table-column>
			<el-table-column label="订单金额" width="80">
				<template slot-scope="scope">
					<p>￥{{scope.row.Total_fee}}</p>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" size='mini' @click='setfun(scope.row)'>操作</el-button>
				</template>
			</el-table-column>
		</el-table>
		<p style="float: left; margin-top: 10px;">共{{total}}条</p>
		<el-pagination style="float: right;margin-top: 10px;" @current-change='pagefun' background
			layout="prev, pager, next" :total="total">
		</el-pagination>
		<el-dialog title="发票操作" :visible.sync="Fpflage" width="35%" center>
			<div id="fpbox_msg">
				<p>发票类型:<span>{{Fpobj.InvoiceType}}</span></p>
				<p v-if="Emflage">税号:<span>{{Fpobj.TaxNumber}}</span></p>
				<p v-else>税号:<el-input style="width: 60%;" id="eminp" v-model="Fpobj.TaxNumber"></el-input>
				<p v-if="Emflage">抬头:<span>{{Fpobj.Header}}</span></p>
				<p v-else>抬头:<el-input style="width: 60%;" id="eminp" v-model="Fpobj.Header"></el-input>
				<p v-if="Emflage">邮箱:<span>{{Fpobj.Email}}</span></p>
				<p v-else>邮箱:<el-input style="width: 60%;" id="eminp" v-model="Fpobj.Email"></el-input>
				<p v-if="Emflage">单位地址:<span>{{Fpobj.UnitAddress}}</span></p>
				<p v-else>单位地址:<el-input v-if="Fpobj.InvoiceType!='个人'" style="width: 60%;" id="eminp" v-model="Fpobj.UnitAddress"></el-input>
				<p v-if="Emflage">电话号码:<span>{{Fpobj.InvoicePhone}}</span></p>
				<p v-else>电话号码:<el-input v-if="Fpobj.InvoiceType!='个人'" style="width: 60%;" id="eminp" v-model="Fpobj.InvoicePhone"></el-input>
				<p v-if="Emflage">开户银行:<span>{{Fpobj.InvoiceBank}}</span></p>
				<p v-else>开户银行:<el-input v-if="Fpobj.InvoiceType!='个人'" style="width: 60%;" id="eminp" v-model="Fpobj.InvoiceBank"></el-input>
				<p v-if="Emflage">银行账号:<span>{{Fpobj.BankAccount}}</span></p>
				<p v-else>银行账号:<el-input v-if="Fpobj.InvoiceType!='个人'" style="width: 60%;" id="eminp" v-model="Fpobj.BankAccount"></el-input>
				</p>
				<p>账户名称:<span>{{Fpobj.UserName}}</span></p>
				<p>商户订单号:<span>{{Fpobj.Out_trade_no}}</span></p>
				<p>商品名:<span>{{Fpobj.GoodsName}}</span></p>
				<p>支付平台:<span>{{Fpobj.Dealer}}</span></p>
				<p>时间:<span>{{Fpobj.Time}}</span></p>
				<p>状态:<span>{{Fpobj.InvoiceState}}</span></p>
				<p>订单金额:<span>{{Fpobj.Total_fee}}</span></p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="Fpflage = false">取 消</el-button>
				<el-button type="primary" v-if="Fpobj.InvoiceState=='未开票'" @click="applyfor">开票</el-button>
				<el-button type="primary" v-if="Emflage&&Fpobj.InvoiceState!='发送成功'" @click="emlfun">修改</el-button>
				<el-button type="primary" v-if="!Emflage &&Fpobj.InvoiceState!='发送成功'" @click="settxtfun">保存
				</el-button>
				<el-button type="primary" v-if="Fpobj.InvoiceState!='未开票'" @click="emel=true,Fpflage = false">发送发票
				</el-button>
			</span>
		</el-dialog>
		<el-dialog title="发送邮件" :visible.sync="emel" width="50%" center>
			<div id="embox">
				<div id="embox_header">
					<p style="border-bottom: 1px solid #dbd9d9;">收件人:<span style="font-weight: bold;">{{Fpobj.Email}}</span></p>
					<p style="border-bottom: 1px solid #dbd9d9;padding-bottom: 5px;" v-if="ems.length>0" v-for="(item,i) in ems" :key='i'>抄送人{{i+1}}:<el-input style="width: 90%;border: none;"
							size='small' v-model="item.chem">
							<template slot="append"><i class="el-icon-delete-solid" @click="splitems(i)"></i></template>
						</el-input>
					</p>
					<p style="cursor:pointer;color:#409EFF;width: 100%;text-align: right;padding-right: 5%; float: right;"
						@click='pushems'>添加抄送人</p>
					<p style="border-bottom: 1px solid #dbd9d9;">主题:<el-input style="width: 95%;" size='small' v-model="From.tit"></el-input>
					</p>
				</div>
				<div id="embox_main">
					<div id="embox_main_left">
						<p>正文:</p>
						<el-input type="textarea" resize="none" style="width: 95%;overflow: auto;"
							:autosize="{ minRows: 10, maxRows: 10}" placeholder="请输入内容" v-model="textarea2">
						</el-input>
					</div>
					<div id="embox_main_right">
						<p>附件:</p>
						<el-upload action="#" ref="upload" :file-list="fileList" :on-change="handleAvatarSuccess"
							:class="uploadDisabled" list-type="picture-card" :auto-upload="false">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
								<span class="el-upload-list__item-actions">
									<!-- <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span> -->
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
						</el-upload>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="emel = false">取 消</el-button>
				<el-button type="primary" @click="sendfun">发送</el-button>
			</span>
		</el-dialog>
		<el-dialog :close-on-click-modal='false' title="邮箱发送错误对照码" :visible.sync="centerDialogVisible" width="50%" center>
			<span>-1 事务无法发生。 找不到指定的 SMTP 主机时，会收到此错误提示信息。</br>
				211 系统状态或系统帮助回复。</br>
				214 服务返回的帮助消息。</br>
				220 SMTP 服务已准备就绪。</br>
				221 SMTP 服务正在关闭传输通道。</br>
				250 电子邮件已成功发送至 SMTP 服务。</br>
				251 用户邮箱不位于接收服务器上；服务器会转发该电子邮件。</br>
				252 指定的用户不是本地用户，但接收 SMTP 服务接受了邮件并试图将其传送。 此状态代码定义符合 RFC 1123，位于http://www.ietf.org。</br>
				354 SMTP 服务已准备好接收电子邮件内容。</br>
				421 SMTP 服务不可用；服务器正在关闭传输通道。</br>
				450 目标邮箱正在使用中。</br>
				451 SMTP 服务无法完成请求。 如果无法解析客户端的 IP 地址（即反向查找失败），则可能出现此错误。如果客户端域已被标识为未经请求的电子邮件（垃圾邮件）的开放中继或开放源，也可能会收到此错误提示信息。</br>
				452 SMTP 服务没有足够的存储空间来完成该请求。</br>
				454 客户端未经过身份验证或不允许使用指定的 SMTP 主机发送邮件。</br>
				500 SMTP 服务无法识别指定的命令。</br>
				501 用于指定命令或参数的语法不正确。</br>
				502 SMTP 服务无法实现指定的命令。</br>
				503 发送命令的顺序不正确。</br>
				504 SMTP 服务无法实现指定的命令参数。</br>
				530 SMTP 服务器配置为仅接受 TLS 连接，并且 SMTP 客户端正在尝试使用非 TLS 连接进行连接。 解决方案是让用户在 SMTP 客户端上设置 EnableSsl= true。</br>
				550 目标邮箱找不到或无法访问。</br>
				551 用户邮箱不位于接收服务器上。 应使用提供的地址信息重新发送。</br>
				552 消息太大，无法存储在目标邮箱中。</br>
				553 用于指定目标邮箱的语法不正确。</br>
				554 事务失败。</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<button id='btns' @click="centerDialogVisible=true" type="button">邮箱发送错误码</button>
	</div>
</template>

<script>
	import formatDate from '../components/datatime.js'
	import Imgfun from '../components/UpImg.js'
	const axi = require('../assets/axi.js')
	export default {
		data() {
			return {
				activeName: '0',
				tableData: [],
				centerDialogVisible:false,
				datetxt: "",
				ems: [], //抄送人
				page: 1,
				textarea2: '尊敬的客户附件中是大总管（上海）科技有限公司给您开具的电子发票敬请查收',
				From: {
					tit: "大总管电子发票"
				},
				emel: false,
				Fpflage: false,
				inpflage: true,
				Emflage: true,
				total: 0,
				options: [{
					value: '1',
					label: '时间从小到大'
				}, {
					value: '2',
					label: '时间从大到小'
				}],
				OrderBy: '1',
				Fpobj: {},
				fileList: [], //图片列表
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				uploadDisabled: "",
				DownloadData: {},
				DownloadState: false
			};
		},
		mounted() {
			let daySart = new Date(new Date(new Date().toLocaleDateString()).getTime());
			let dayEnd = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
			daySart = formatDate(daySart);
			dayEnd = formatDate(dayEnd);
			console.log(daySart)
			console.log(dayEnd)
			let Arr = [daySart, dayEnd]
			this.datetxt = Arr
			this.SELFUN()
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab.name);
				if (tab.name == '1' || tab.name == '2' || tab.name == '3') {
					this.datetxt = []
				} else {
					let daySart = new Date(new Date(new Date().toLocaleDateString()).getTime());
					let dayEnd = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
					daySart = formatDate(daySart);
					dayEnd = formatDate(dayEnd);
					console.log(daySart)
					console.log(dayEnd)
					let Arr = [daySart, dayEnd]
					this.datetxt = Arr
				}
				this.SELFUN()
			},
			SELFUN() {
				let InvoiceState = ''
				let OrderBy = true
				if (this.activeName == '0') {
					InvoiceState = ''
				} else {
					InvoiceState = this.activeName
				}
				if (this.OrderBy == '1') {
					OrderBy = true
				} else {
					OrderBy = false
				}
				let St = formatDate(new Date(this.datetxt[0]))
				let Ft = formatDate(new Date(this.datetxt[1]))
				let data = {
					STime: St,
					FTime: Ft,
					PageIndex: this.page,
					InvoiceState: InvoiceState,
					OrderBy: OrderBy
				}
				console.log(data)
				axi.post('Invoice/Invoice', data).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						this.total = res.data.Count
						for (let i = 0; i < res.data.Dto.length; i++) {
							if (res.data.Dto[i].InvoiceState == '1') {
								res.data.Dto[i].InvoiceState = '未开票'
							}
							if (res.data.Dto[i].InvoiceState == '2') {
								res.data.Dto[i].InvoiceState = '已申请发票'
							}
							if (res.data.Dto[i].InvoiceState == '3') {
								res.data.Dto[i].InvoiceState = '邮箱错误'
							}
							if (res.data.Dto[i].InvoiceState == '4') {
								res.data.Dto[i].InvoiceState = '发送成功'
							}
							if (res.data.Dto[i].InvoiceType  == '1') {
								res.data.Dto[i].InvoiceType  = '个人'
							}
							if (res.data.Dto[i].InvoiceType  == '2') {
								res.data.Dto[i].InvoiceType  = '企业'
							}
							res.data.Dto[i].Total_fee = res.data.Dto[i].Total_fee / 100
							res.data.Dto[i].Time = res.data.Dto[i].Time.replace('T', ' ')
						}
						this.tableData = res.data.Dto
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			pagefun(val) {
				this.page = val
				this.SELFUN()
			},
			setfun(val) {
				console.log(val)
				this.Fpobj = val
				this.Fpflage = true
				this.Emflage = true
			},
			emlfun() { //修改邮箱
				this.Emflage = false
				this.$nextTick(() => {
					document.getElementById('eminp').focus()
				})
			},
			handleAvatarSuccess(res, file) {
				console.log(res)
				if(res.size)
				console.log(file)
				this.fileList = file
				if (file.length > 0) {
					this.uploadDisabled = 'disabled'
				} else {
					this.uploadDisabled = ''
				}
			},
			// handlePictureCardPreview(file) {
			// 	console.log(file)
			// 	this.DownloadState = false
			// 	this.dialogImageUrl = file.url;
			// 	this.dialogVisible = true;
			// },
			handleRemove(file) {
				// this.$refs.upload.clearFiles();
				console.log(file)
				this.uploadDisabled = ''
				this.fileList = []
			},
			sendfun() {
				let formData = new FormData(); //  将文件封装进FormData
				let Image = ''
				// console.log(this.fileList)
				this.fileList.forEach(file => {
					if (file.status == "ready") { //如果有替换图片 图片名称就是替换的
						Image = this.Fpobj.Out_trade_no + '.' + file.name.split('.')[1]
						formData.append('file', file.raw, file.uid + "-" + file.name)
					} else { //没有替换图片
						Image = this.ProductData.Image
					}
				})
				console.log(Imgfun.client)
				let client = Imgfun.client
				this.pushFile(this.fileList[0], Image, client)
				console.log(Image)
			},
			pushFile(file, imgname, client) { //上传方法
				console.log(file);
				if (file) {
					let data = file.raw //file类型文件
					// imgname传到oss上的名字
					// // 调用上传方法
					client.multipartUpload('OfficialWebsite/invoice/' + imgname, data, {
						progress: function*(percentage) {
							let fileloadingNum = Math.ceil(percentage * 100) + "%";
							console.log(fileloadingNum); // 上传文件进度
						},
					}).then((result) => { //上传到OSS后调用存入数据库
						console.log(result)
						let url = 'http://bxexample.oss-cn-shanghai.aliyuncs.com/' + result.name
						this.send(url,result.name)
					})
				} else {
					this.send()
				}

			},
			applyfor() { //开票事件
				axi.post('Invoice/Apply?Out_trade_no=' + this.Fpobj.Out_trade_no).then((res) => {
					if (res.data.Code == 1000) {
						this.$message.success('开票成功!')
						this.Fpflage = false
						this.SELFUN()
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			pushems() { //添加抄送人
				this.ems.push({
					chem: ""
				})
			},
			splitems(i) { //切除抄送人
				this.ems.splice(i, 1)
			},
			send(img,delname) {
				// this.emel=false
				let mailToArray = []
				let mailCcArray = []
				let attachmentsPath = []
				if (img) {
					attachmentsPath.push(img)
				}
				mailToArray.push(this.Fpobj.Email)
				if (this.ems.length > 0) {
					for (let i = 0; i < this.ems.length; i++) {
						console.log(this.ems[i])
						mailCcArray.push(this.ems[i].chem)
					}
				}
				console.log(mailCcArray)
				let data = {
					mailToArray: mailToArray,
					mailCcArray: mailCcArray,
					mailSubject: this.From.tit,
					mailBody: this.textarea2,
					attachmentsPath: attachmentsPath,
					Out_trade_no: this.Fpobj.Out_trade_no
				}
				console.log(data)
				axi.post('Invoice/Send', data).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						if (res.data.Msg == '250') {
							this.$message.success('发送成功!')
							console.log(delname)
							if(delname){
								let client = Imgfun.client
								let result = client.delete(delname);
							}
							// console.log(result);
							this.From.tit = '大总管电子发票'
							this.textarea2 = '尊敬的客户附件中是大总管（上海）科技有限公司给您开具的电子发票敬请查收'
							this.ems = []
							this.fileList = []
							this.SELFUN()
							console.log(img)
							// let result = client.delete('OfficialWebsite/invoice/'+img);
							// console.log(result);
						} else {
							const h = this.$createElement;
							this.$message.error(res.data.Msg)
							this.$notify({
								title: '错误码:"' + res.data.Msg + '"',
								message: h('i', {
									style: 'color: red'
								}, '商户号：' + this.Fpobj.Out_trade_no + ',请将错误信息给到开发人员!'),
								duration: 0
							});
						}
						this.emel = false
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			settxtfun() {
				console.log(this.Fpobj)
				let data = {
					Out_trade_no: this.Fpobj.Out_trade_no,
					Header: this.Fpobj.Header,
					TaxNumber: this.Fpobj.TaxNumber,
					Email: this.Fpobj.Email,
					UnitAddress: this.Fpobj.UnitAddress,
					InvoicePhone:this.Fpobj.InvoicePhone,
					InvoiceBank:this.Fpobj.InvoiceBank ,
					BankAccount:this.Fpobj.BankAccount 
				}
				console.log(data)
				axi.post('Invoice/UpMessage', data).then((res) => {
					if (res.data.Code == 1000) {
						this.$message.success('修改成功!')
						this.Emflage = true
						this.Fpflage = false
						this.SELFUN()
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			}


		}
	};
</script>

<style scoped="scoped" lang="less">
	#invbox {
		width: 96%;
		margin-left: 2%;
		margin-top: 10vh;

		/deep/.el-tabs__nav {
			width: 100%;
			text-align: center;
		}

		/deep/.el-tabs__item {
			width: 20%;
		}

		button {
			background-color: #fd3f31;
			color: #FFFFFF;
			border: none;
		}

		#fpbox_msg {
			width: 80%;
			margin-left: 10%;

			p {
				height: 40px;
				line-height: 40px;
				margin: 0;
				font-weight: bold;
				font-size: 18px;

				span {
					font-weight: 500;
					margin-left: 5px;
					font-size: 16px;
				}
			}
		}
	}

	/deep/.disabled .el-upload--picture-card {
		display: none;
	}

	#embox_header {
		p {}
	}

	#embox_main {
		width: 100%;
		height: 100%;
		display: flex;

		#embox_main_left {
			width: 65%;
		}

		#embox_main_right {
			width: 25%;
		}
	}

	/deep/.el-upload--picture-card {
		height: 220px;
		line-height: 220px;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
		height: 220px;
		line-height: 220px;
	}

	/deep/.el-upload-list__item {
		height: 220px;
		line-height: 220px;
	}
	#btns{
		width: 60px;
		height: 80px;
		border-radius: 5px;
		position: fixed;
		right: 10px;
		bottom: 20%;
	}
	#embox_header{
		/deep/.el-input__inner{
			border: none;
		}
		/deep/.el-input-group__append{
			background-color: #FFFFFF;
			border: none;
			i{
				cursor: pointer;
				font-size: 20px;
			}
		}
	}
	
</style>
<style type="text/css">
	::-webkit-scrollbar {
	width: 0px;
	height: 10px;
	}
</style>
